import { HashRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Components/PrivateRoute";
import ClientData from "./Pages/ClientData";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import SingleCompany from "./Pages/SingleCompany";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/showClientData" element={<PrivateRoute><ClientData /></PrivateRoute>} />
        <Route path="/singleCompany/:id" element={<PrivateRoute><SingleCompany /></PrivateRoute>} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </HashRouter>
  );
}

export default App;