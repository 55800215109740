import React, { useEffect, useState } from 'react'

function SingleClient({ client }) {

    const [clientDataKeys, setClientDataKeys] = useState([]);


    useEffect(() => {

        const clientDataObjectKeys = Object.keys(client);
        const showAbleKeys = clientDataObjectKeys.filter(key => key !== "_id" && key !== "__v" && key !== "createdAt" && key !== "updatedAt" && key !== "companyId");
        setClientDataKeys(showAbleKeys);
    })

    console.log(typeof client.arrayobject);

    return (
        <div key={client._id} className='w-full grid grid-cols-4 py-3 px-1 border-b-[1px] hover:bg-gray-50'>
            <div className='flex'>
                <p className=' text-gray-600 text-sm font-semibold'>{client.companyId.companyName}</p>
            </div>

            <div className='flex justify-center'>
                <p className='  text-gray-600 text-sm font-semibold'>{client.firstname}</p>
            </div>
            <div className='flex justify-center'>
                <p className=' text-gray-600 text-sm font-semibold'>{client.age}</p>
            </div>
            <div className='flex justify-self-end'>
                <p className='  text-gray-600 text-sm font-semibold'>{client.mobilenumber}</p>
            </div>
        </div>
    )
}

export default SingleClient