import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Host } from '../Data';
import { AllCompanyFatchSuccess, UpdateCompanySuccess, AddCompanySuccess, DeleteCompanySuccess } from "../Redux/Companies_slice";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Companies() {

    const AllCompanies = useSelector(state => state.Companies.Companies);
    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState(false);
    const [isAddModeOpen, setIsAddModeOpen] = useState(false);
    const [currantCompany, setCurrantCompany] = useState(null);
    const User = useSelector(state => state.User.User);
    const dispatch = useDispatch()
    const [companyName, setCompanyName] = useState('');

    const toggleUpdateMode = (company) => {
        setIsUpdateModeOpen(!isUpdateModeOpen);
        setCurrantCompany(company);
        setCompanyName(company.companyName);
        if (isAddModeOpen) {
            setIsAddModeOpen(false)
        }
    }

    const toggleAddMode = () => {
        setIsAddModeOpen(!isAddModeOpen);
        setCompanyName('');
        if (isUpdateModeOpen) {
            setIsUpdateModeOpen(false)
        }
    }


    const CreateCompanyFunc = async () => {

        const companyData = {
            companyName
        }

        try {

            const response = await axios.post(`${Host}/api/company/create_company`, companyData, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })

            setCompanyName('');
            dispatch(AddCompanySuccess(response.data));
            toast.success('Company Created Successfully')
            toggleAddMode()


        } catch (err) {

            if (err.response.data === "company already exist") {
                toast.error('Company Already Exist')
            }

            console.log(err);
        }
    };

    const updateCompanyName = async (id) => {

        if (!companyName) {
            toast.error('Please Enter Company Name')
            return
        }

        try {

            const res = await axios.put(`${Host}/api/company/update_company/${id}`, { companyName }, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })

            setCompanyName('');
            dispatch(UpdateCompanySuccess(res.data));
            toast.success('Company Updated Successfully')
            toggleUpdateMode(res.data)

        } catch (error) {

            toast.error('Something went wrong')
            console.log(error);
        }
    }


    const deleteCompany = async (id) => {

        try {

            await axios.delete(`${Host}/api/company/delete_company/${id}`, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })

            dispatch(DeleteCompanySuccess(id));
            toast.success('Company Deleted Successfully')

        } catch (err) {
            toast.error('Something went wrong')
        }
    }

    useEffect(() => {

        const fetchCompanies = async () => {

            try {

                const response = await axios.get(`${Host}/api/company/`, {
                    headers: {
                        'Authorization': `Bearer ${User.token}`
                    }
                })

                dispatch(AllCompanyFatchSuccess(response.data));

            } catch (err) {

                console.log(err);
            }
        }
        fetchCompanies();

    }, [User])


    return (

        <div className='w-full lg:overflow-hidde'>
            <div className=' w-full  bg-white shadow p-5 px-5 sm:px-10 rounded'>

                <div className=' grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-0 mb-3'>
                    <p className='font-semibold text-lg'>Companies</p>
                    {
                        !isAddModeOpen ?
                            <div className=' w-full flex items-center justify-end'>
                                <button onClick={toggleAddMode} className='border-[1] rounded p-1 text-green-500 shadow shadow-green-300 ml-2 hover:scale-105 hover:text-green-600 transition-all duration-100 ease-in'>Add New</button>
                            </div>
                            :
                            <div className=''>
                                <div className=' flex justify-between'>
                                    <input type="text" placeholder='Enter Company Name' value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} className=' w-full focus:outline-none border border-blue-500 rounded mr-5 py-1 px-2' />
                                    <div className=' flex'>
                                        <button onClick={CreateCompanyFunc} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Create</button>
                                        <button onClick={toggleAddMode} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Close</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {
                    AllCompanies.length > 0 && AllCompanies.map((company, index) => {
                        return (
                            <div key={index} className=' w-full flex justify-between flex-wrap sm:flex-row flex-col py-3 border-b-[1px] border-gray-100'>

                                {
                                    isUpdateModeOpen && currantCompany && currantCompany._id === company._id ?
                                        <input className=' w-full sm:w-1/2 focus:outline-none border-[1px] border-blue-400 rounded mr-5 py-[]6px px-2' placeholder='Enter Company Name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                        :
                                        <div className='w-full sm:w-1/2  text-gray-700 font-semibold my-2 sm:my-0 flex items-center'>
                                            <span className=''>{company.companyName}</span>
                                        </div>
                                }
                                {
                                    isUpdateModeOpen && currantCompany && currantCompany._id === company._id ?
                                        <div className=' flex items-center sm:mt-0 mt-5'>
                                            <button onClick={() => updateCompanyName(company._id)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Save</button>
                                            <button onClick={() => setIsUpdateModeOpen(false)} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Closes</button>
                                        </div>

                                        :

                                        <div className=' flex items-center sm:mt-0 mt-3'>
                                            <Link to={`/singleCompany/${company._id}`}>
                                                <button className=' border-[1px] rounded p-1 text-green-500 shadow shadow-green-200 hover:scale-110 hover:text-green-600 transition-all duration-100 ease-in mr-2'>View</button>
                                            </Link>
                                            <button onClick={() => toggleUpdateMode(company)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Edit</button>
                                            <button onClick={() => { deleteCompany(company._id) }} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Delete</button>
                                        </div>
                                }
                            </div>
                        )
                    })
                }

            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default Companies