import React, { useState } from 'react'

function FilterFild({ optionsArray, filtersData, setFiltersData, index }) {

    const [selectedFilterKey, setSelectedFilterKey] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [filterOption, setFilterOption] = useState(null);

    const changeFilterKey = (key) => {

        if (!selectedFilterKey) {

            setFiltersData([...filtersData, { key: key, value: filterValue, option: filterOption }]);

        } else {

            let findKey = filtersData.find(item => item.key === selectedFilterKey);

            if (findKey) {

                const findIndex = filtersData.findIndex(item => item.key === selectedFilterKey);
                const newFiltersData = [...filtersData];
                newFiltersData[findIndex] = { key: key, value: filterValue, option: filterOption };
                setFiltersData(newFiltersData);

            } else {

                setFiltersData([...filtersData, { key: key, value: filterValue, option: filterOption }]);
            }
        }
        setSelectedFilterKey(key);
    }

    const changeFilterValue = (value) => {

        setFilterValue(value);
        setFiltersData(filtersData.map(filter => {

            if (filter.key === selectedFilterKey) {
                return { ...filter, value: value, option: filterOption }
            } else {
                return filter
            }
        }));
    }

    const changeFilterOption = (option) => {

        setFilterOption(option);
        setFiltersData(filtersData.map(filter => {

            if (filter.key === selectedFilterKey) {
                return { ...filter, value: filterValue, option: option }
            } else {
                return filter
            }
        }));
    }

    return (
        <div className=' w-full py-2 flex justify-between items-center' >
            <select onChange={(e) => changeFilterKey(e.target.value)} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                <option value="">Select Property</option>
                {
                    optionsArray.map((key) => {
                        return (
                            <option key={key} value={key}>{key}</option>
                        )
                    })
                }
            </select>

            {
                selectedFilterKey &&

                <input type="text" value={filterValue} onChange={(e) => changeFilterValue(e.target.value)} placeholder={`${"Enter " + selectedFilterKey}`} className=" border border-blue-500 rounded py-1 px-2 focus:outline-none" />
            }
            {
                selectedFilterKey &&

                <select value={filterOption} onChange={(e) => { changeFilterOption(e.target.value) }} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                    <option value="">Filter Option</option>
                    <option value="$gt">Greater then</option>
                    <option value="$gte">Greater then and equal</option>
                    <option value="$lt">Smaller then</option>
                    <option value="$lte">Smaller then and equal</option>
                    <option value="">Equal</option>
                </select>
            }
        </div>
    )
}

export default FilterFild