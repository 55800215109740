import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Host } from '../Data';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DataFormate({ setIsCreateDataFormateOpen, companyId, setCurrantCompany }) {

    // single property data
    const [propertyName, setPropertyName] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [propertyValue, setPropertyValue] = useState('');

    // multiple property data

    const [propertyNames, setPropertyNames] = useState([]);
    const [propertyValues, setPropertyValues] = useState([]);
    const user = useSelector(state => state.User.User);
    const [companyKeys, setCompanyKeys] = useState([]);
    const [propartyQuantity, setPropartyQuantity] = useState(0);
    const [showObjectPropartySlection, setShowObjectPropartySlection] = useState(false);
    const [randomArray, setRandomArray] = useState([]);


    const clearData = () => {
        setPropertyName('');
        setPropertyType('');
        setPropertyValue('');
        setPropertyNames([]);
        setPropertyValues([]);
        setPropartyQuantity(0);
        setShowObjectPropartySlection(false);
        setRandomArray([]);
    }


    const showObjectPropartySlectionFunc = () => {

        if (propartyQuantity > 0) {

            const newArray = [];

            for (let i = 0; i < propartyQuantity; i++) {

                newArray.push(i);
            }

            setRandomArray(newArray);
            setShowObjectPropartySlection(true);

        } else {

            toast.error("Please enter property quantity");
        }
    }

    const changePropartyType = (value) => {
        setPropartyQuantity(0)
        setRandomArray([]);
        setShowObjectPropartySlection(false);
        setPropertyType(value);
    }

    const CreateObjectPrepertyName = (Name, index) => {

        const newArray = [...propertyNames];

        newArray[index] = {
            name: Name,
            index: index
        };

        setPropertyNames(newArray);
    }

    const CreateObjectPrepertyValue = (Value, index) => {

        const newArray = [...propertyValues];

        newArray[index] = {
            value: Value,
            index: index
        };

        setPropertyValues(newArray);
    }


    const SaveDataFormat = async () => {

        if (propertyType === '') {
            toast.error("Please select the property type");
            return;
        }

        if (propertyName === '') {
            toast.error("Please enter the property name");
            return;
        }

        if (propertyType === 'String') {

            if (propertyValue === '') {
                toast.error("Please enter the property value");
                return;
            }

            const dataFormat = {}

            dataFormat[`${propertyName}`] = {
                type: propertyType,
                value: propertyValue
            }

            const res = await axios.post(`${Host}/api/company/create_data_format/${companyId}`, dataFormat, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            clearData();
            toast.success("Data format created successfully");
            setCurrantCompany(res.data);

        } else if (propertyType === 'Object') {

            if (propartyQuantity === 0) {
                // alert('Please select the quantity of property');
                toast.error("Please select the quantity of property");
                return;

            } else if (propertyNames.length === 0 || propertyValues.length === 0) {
                // alert('Please enter the property name and value');
                toast.error("Please enter the property name and value");
                return;
            }

            if (propertyNames.length !== propertyValues.length) {
                // alert('Please enter all the property name and value');
                toast.error("Please enter all the property name and value");
                return;
            }

            const dataFormat = {
                [`${propertyName}`]: {
                    type: propertyType,
                    value: {}
                }
            }

            for (let i = 0; i < propertyNames.length; i++) {

                dataFormat[`${propertyName}`].value[propertyNames[i].name] = propertyValues[i].value;
            }

            const res = await axios.post(`${Host}/api/company/create_data_format/${companyId}`, dataFormat, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            setCurrantCompany(res.data);
            toast.success("Data format created successfully");
            clearData();

        } else if (propertyType === 'Array') {

            if (propertyValue === '') {
                toast.error("Please enter the property value");
                return;
            }

            const dataFormat = {}

            dataFormat[`${propertyName}`] = {
                type: propertyType,
                value: propertyValue
            }

            const res = await axios.post(`${Host}/api/company/create_data_format/${companyId}`, dataFormat, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            setCurrantCompany(res.data);
            toast.success("Data format created successfully");
            clearData();

        } else if (propertyType === 'ArrayObject') {

            if (propartyQuantity === 0) {
                toast.error("Please select the quantity of property");
                return;
            }

            if (propertyNames.length === 0 || propertyValues.length === 0) {

                toast.error("Please enter the property name and value");
                return;
            }

            if (propertyNames.length !== propertyValues.length) {
                toast.error("Please enter all the property name and value");
                return;
            }

            const dataFormat = {
                [`${propertyName}`]: {
                    type: propertyType,
                    value: {}
                }
            }

            for (let i = 0; i < propertyNames.length; i++) {

                dataFormat[`${propertyName}`].value[propertyNames[i].name] = propertyValues[i].value;
            }

            const res = await axios.post(`${Host}/api/company/create_data_format/${companyId}`, dataFormat, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            clearData();
            toast.success("Data format created successfully");
            setCurrantCompany(res.data);
        }
    }


    useEffect(() => {

        const getCompanyKeys = async () => {

            const response = await axios.get(`${Host}/api/company/get_keys/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })

            setCompanyKeys(response.data);
        }

        getCompanyKeys();

    }, [])


    return (
        <div className='fixed w-screen h-full overflow-hidden top-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-50'>
            <div className=' w-full h-full overflow-hidden p-5 flex justify-center items-center box-border'>
                <div className='w-full sm:w-[800px] h-full sm:min-h-[600px]  overflow-y-scroll scrollbar bg-white shadow shadow-gray-300 rounded relative p-5 sm:p-10'>
                    <p className=' text-xl text-center pb-5 font-semibold border-b border-gray-300'>Data formate</p>
                    <div className='mt-5'>
                        <label htmlFor="" className=' block font-semibold mb-2'>Property Name</label>
                        <input type="text" placeholder='Enter property name' value={propertyName} onChange={(e) => setPropertyName(e.target.value)} className="py-1 px-2 rounded focus:outline-none w-full border border-blue-500" />
                    </div>
                    <div className=' mt-3'>
                        <p className='font-semibold mb-3'>Property Type</p>
                        <select name="" id="" className=' focus:outline-none py-1 px-2 rounded w-full border border-blue-500' value={propertyType} onChange={(e) => changePropartyType(e.target.value)}>
                            <option value="">Select Type</option>
                            <option value="String">Simple</option>
                            <option value="Array">Array</option>
                            <option value="Object">Object</option>
                            <option value="ArrayObject">Array Object</option>
                        </select>
                    </div>
                    {
                        propertyType === "String" && companyKeys.length > 0 &&

                        <div className=' mt-3'>
                            <p className='font-semibold mb-2'>Select Key</p>
                            <select value={propertyValue} onChange={(e) => setPropertyValue(e.target.value)} name="" id="" className=' py-1 px-2 rounded focus:outline-none w-full border border-blue-500' >
                                <option value="">Select Key</option>
                                {
                                    companyKeys.map(key => {
                                        return (
                                            <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        propertyType === "Array" && companyKeys.length > 0 &&

                        <div className=' mt-3'>
                            <p className='font-semibold mb-2'>Select Key</p>
                            <select value={propertyValue} onChange={(e) => setPropertyValue(e.target.value)} name="" id="" className='py-1 px-2 rounded focus:outline-none w-full border border-blue-500' >
                                <option value="">Select Key</option>
                                {
                                    companyKeys.map(key => {
                                        return (
                                            <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        companyKeys.length > 0 && propertyType === "Object" &&

                        <div className=' mt-3'>
                            <div>
                                <p className=' mb-3 font-semibold'>How many property do you want to add in the object?</p>
                                <div className=' grid grid-flow-col gap-3'>
                                    <input type="number" value={propartyQuantity} onChange={(e) => { setPropartyQuantity(e.target.value) }} className=' focus:outline-none py-1 px-2 rounded border border-blue-500' />
                                    <button onClick={showObjectPropartySlectionFunc} className='shadow shadow-purple-300 p-1 rounded text-purple-600 hover:scale-105 transition-all ease-in'>Add</button>
                                </div>
                            </div>

                            {
                                showObjectPropartySlection &&

                                <div>

                                    {
                                        randomArray.map(i => {
                                            return (
                                                <div key={i} className=" my-5 grid grid-cols-2 gap-3">
                                                    <div className=' w-full'>
                                                        <label htmlFor="" className=' block font-semibold mb-2'>Property name for object key</label>
                                                        <input type="text" onChange={(e) => CreateObjectPrepertyName(e.target.value, i)} placeholder='Enter property name' className="py-1 px-2 rounded focus:outline-none w-full border border-blue-500" />
                                                    </div>

                                                    <div className=' w-full justify-self-end'>
                                                        <p className='font-semibold mb-2'>Select key</p>
                                                        <select name="" id="" onChange={(e) => CreateObjectPrepertyValue(e.target.value, i)} className=' focus:outline-none border border-blue-500 py-1 px-2 rounded' >
                                                            <option value="">Select Key</option>
                                                            {
                                                                companyKeys.map(key => {
                                                                    return (
                                                                        <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            }


                        </div>
                    }
                    {
                        companyKeys.length > 0 && propertyType === "ArrayObject" &&

                        <div className=' mt-3'>
                            <div>
                                <p className=' mb-5 font-semibold'>How many property do you want to add in the Array object?</p>
                                <div className=' grid grid-flow-col gap-3'>
                                    <input type="number" value={propartyQuantity} onChange={(e) => { setPropartyQuantity(e.target.value) }} className=' focus:outline-none py-1 px-2 rounded border border-blue-500' />
                                    <button onClick={showObjectPropartySlectionFunc} className=' shadow shadow-purple-300 p-1 rounded text-purple-600 hover:scale-105 transition-all ease-in '>Add</button>
                                </div>
                            </div>

                            {
                                showObjectPropartySlection &&

                                <div>

                                    {
                                        randomArray.map(i => {
                                            return (
                                                <div key={i} className="mt-5 grid grid-cols-2 gap-3">
                                                    <div >
                                                        <label htmlFor="" className=' block font-semibold mb-2'>Property name for object key</label>
                                                        <input onChange={(e) => CreateObjectPrepertyName(e.target.value, i)} type="text" placeholder='Enter property name' className="py-1 px-2 rounded focus:outline-none w-full border border-blue-500" />
                                                    </div>

                                                    <div>
                                                        <label htmlFor="" className=' block font-semibold mb-2'>Select key</label>
                                                        <select name="" onChange={(e) => CreateObjectPrepertyValue(e.target.value, i)} id="" className=' focus:outline-none border border-blue-500 py-1 px-2 rounded' >
                                                            <option value="">Select Key</option>
                                                            {
                                                                companyKeys.map(key => {
                                                                    return (
                                                                        <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            }


                        </div>
                    }

                    <button onClick={SaveDataFormat} className=' absolute bottom-5 right-1/2 translate-x-1/2 shadow shadow-blue-300 text-blue-600 px-2 py-1 rounded transition-all ease-in hover:scale-105'>Save</button>

                </div>
            </div>
            <button onClick={() => { setIsCreateDataFormateOpen(false) }} className=' z-[100] py-1 px-2 rounded bg-red-500 text-white fixed top-3 right-3 hover:scale-105 transition-all ease-in '>Close</button>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />

        </div>
    )
}

export default DataFormate