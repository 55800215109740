import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { BsClipboardData } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Sidebar({ Home, ClientDataComponent }) {

    return (
        <div className={`bg-white pt-24 shadow shadow-gray-300 w-[70px] sm:w-[200px] min-h-screen rounded px-5 sm:px-8`}>
            <ul>
                <Link to="/">
                    <li className=' flex items-center group cursor-pointer mb-5'>
                        <AiOutlineHome className={`${Home ? "text-blue-500" : " text-gray-500"} text-xl mr-3 group-hover:text-blue-500`} />
                        <span className={`${Home ? "text-blue-500" : " text-gray-500"} mr-3 font-semibold group-hover:text-blue-500 hidden sm:inline-block`}>Dashboard</span>
                    </li>
                </Link>
                <Link to="/showClientData">
                    <li className=' flex items-center group cursor-pointer mb-5'>
                        <BsClipboardData className={`${ClientDataComponent ? "text-blue-500" : " text-gray-500"} text-xl mr-3 group-hover:text-blue-500`} />
                        <span className={`${ClientDataComponent ? "text-blue-500" : " text-gray-500"} mr-3 font-semibold group-hover:text-blue-500 hidden sm:inline-block`}>Client Data</span>
                    </li>
                </Link>
            </ul>

        </div>
    )
}

export default Sidebar