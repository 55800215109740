import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";


function PrivateRoute({ children }) {

    const User = useSelector(state => state.User.User);

    return (
        User ? children : <Navigate to="/login" />
    )
}

export default PrivateRoute