import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { Host } from "../Data"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilterFild from '../Components/FilterFild';
import SingleClient from '../Components/SingleClient';

function ClientData() {

    const User = useSelector(state => state.User.User);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [clients, setClients] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [filterSectionOpen, setFilterSectionOpen] = useState(false);
    const [optionsArray, setOptionsArray] = useState([]);
    const [filtersData, setFiltersData] = useState([]);
    const [filterQuentity, setFilterQuentity] = useState([]);


    const toggleFilterSection = () => {

        if (!selectedCompanyId) {
            toast.error("Please select company");
            return;
        }

        if (filterQuentity.length <= 0) {
            setFilterQuentity([...filterQuentity, 1]);
        }

        setFilterSectionOpen(true);
        setSelectedCompany(companies.find(company => company._id === selectedCompanyId));

        const company = companies.find(company => company._id === selectedCompanyId);
        const options = [];

        Object.keys(company.dataFormat).forEach(key => {

            if (company.dataFormat[key].type === "String") {

                options.push(key);

            } else if (company.dataFormat[key].type === "Object") {

                Object.keys(company.dataFormat[key].value).forEach(k => {
                    options.push(`${key}.${k}`);
                });

            } else if (company.dataFormat[key].type === "Array") {

                options.push(key);

            } else if (company.dataFormat[key].type === "ArrayObject") {

                Object.keys(company.dataFormat[key].value).forEach(k => {
                    options.push(`${key}.${k}`);
                });
            }
        })

        setOptionsArray(options);
    }

    const IncreaseFilterFild = () => {
        setFilterQuentity([...filterQuentity, 1]);
    }

    const FatchClientData = async () => {

        if (filterQuentity.length !== filtersData.length) {
            toast.error("Please select all filters");
            return;
        }

        filtersData.forEach(filter => {

            if (filter.value === "" || filter.value === null) {
                toast.error("Please fill all filters");
                return;
            }
        })

        const result = await axios.post(`${Host}/api/client/getClientData/${selectedCompanyId}`, filtersData, {
            headers: {
                'Authorization': `Bearer ${User.token}`
            }
        });

        setClients(result.data);
    }


    useEffect(() => {

        const fetchData = async () => {
            const result = await axios.get(`${Host}/api/company/`, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            });
            setCompanies(result.data);
        }
        fetchData();

    }, [User]);

    return (
        <div className=' bg-gray-100 min-h-screen w-full'>
            <Header />
            <div className=' w-full pt-18 flex'>
                <Sidebar ClientDataComponent />
                <div className='pt-20 ml-3 sm:ml-5 w-[calc(100%-70px)] sm:w-[calc(100%-200px)] md:px-16 lg:px-20 xl:px-32 overflow-hidden'>
                    <div className=' w-full mt-3 pr-5 overflow-hidden'>
                        <div className=' w-full bg-white rounded p-5'>
                            <p className=' text-center font-semibold text-xl'>Client Data</p>
                            <div className=' w-full py-5 flex justify-between items-center'>
                                <select value={selectedCompanyId} onChange={(e) => { setSelectedCompanyId(e.target.value) }} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                                    <option value={""} >Select Company</option>
                                    {
                                        companies.length > 0 && companies.map((company) => {
                                            return (
                                                <option value={company._id} key={company._id}>{company.companyName}</option>
                                            )
                                        })
                                    }
                                </select>
                                <button onClick={toggleFilterSection} className=' shadow shadow-blue-300 rounded p-1 text-blue-700 hover:scale-105 transition-all ease-in'>Add Company</button>
                            </div>
                            {
                                filterSectionOpen &&
                                <div className=' w-full'>
                                    <p className=' text-center font-semibold mb-5'>Select filter property</p>
                                    {
                                        selectedCompany && optionsArray.length > 0 && filterQuentity.length > 0 &&

                                        <div className=' w-full flex justify-end'>
                                            <button onClick={IncreaseFilterFild} className=' shadow shadow-blue-300 rounded p-1 text-blue-700 hover:scale-105 transition-all ease-in mr-3'>Add Another Filter</button>
                                            <button onClick={FatchClientData} className=' shadow shadow-purple-300 rounded p-1 text-purple-700 hover:scale-105 transition-all ease-in'>Fatch Data</button>
                                        </div>
                                    }
                                    {
                                        selectedCompany && optionsArray.length > 0 && filterQuentity.length > 0 &&
                                        <div className=' mt-2'>
                                            {
                                                filterQuentity.map((filter, index) => {
                                                    return (
                                                        <FilterFild optionsArray={optionsArray} filtersData={filtersData} setFiltersData={setFiltersData} index={index} />
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }

                            {
                                clients.length > 0 &&
                                <div className=' w-full mt-5'>
                                    <p className=' text-center font-semibold mb-5'>Client List</p>
                                    <div className=' px-5'>
                                        <div className='w-full mt-3 grid grid-cols-4 justify-items-center p-3 rounded border-b-[1px] border-gray-100 font-semibold text-gray-700 bg-gray-100'>
                                            <span className=' font-semibold text-gray-900 justify-self-start'>Company</span>
                                            <span className=' font-semibold text-gray-900'>First Name</span>
                                            <span className=' font-semibold text-gray-900'>Age</span>
                                            <span className=' font-semibold text-gray-900 justify-self-end'>Mobile Number</span>
                                        </div>
                                        {
                                            clients.map((client) => {
                                                return (
                                                    <SingleClient key={client._id} client={client} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>

    )
}

export default ClientData