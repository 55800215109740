import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import axios from 'axios';
import { Host } from '../Data';
import DataFormate from '../Components/DataFormate';
import DataPoint from '../Components/DataPoint';
import PrimaryKeySelect from '../Components/PrimaryKeySelect';
import DataFormatComponent from '../Components/DataFormatComponent';

function SingleCompany() {

    const user = useSelector(state => state.User.User);
    const [isDataPointOpen, setIsDataPointOpen] = useState(false);
    const [companyToken, setCompanyToken] = useState('');

    const location = useLocation();
    let companyId = location.pathname.split('/')[2];
    const [currantCompany, setCurrantCompany] = useState(null);
    const [isCreateDataFormateOpen, setIsCreateDataFormateOpen] = useState(false);
    const [isPrimaryKeySectionOpen, setIsPrimaryKeySectionOpen] = useState(false);


    const createCompanyToken = async () => {

        try {

            const response = await axios.post(`${Host}/api/company/create_company_token`, { companyId }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })

            setCompanyToken(response.data);

        } catch (error) {

            console.log(error.response.data)
        }
    }


    useEffect(() => {

        const getCompany = async () => {

            const response = await axios.get(`${Host}/api/company/get_company/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })

            setCurrantCompany(response.data);
        }

        getCompany();

    }, []);



    return (
        <div className=' bg-gray-100 m-h-screen w-full overflow-hidden'>
            <Header />
            <div className='w-full pt-18 flex'>
                <Sidebar />
                <div className='pt-20 ml-3 sm:ml-5 w-[calc(100%-70px)] sm:w-[calc(100%-200px)] md:px-16 lg:px-20 xl:px-32 overflow-hidden'>
                    <div className=' w-full pr-5 overflow-hidden pb-5'>
                        {
                            currantCompany &&

                            <div className=' w-full'>
                                <div className='w-full mt-3'>
                                    <p className=' text-lg sm:text-xl font-semibold text-center'> {currantCompany.companyName}</p>
                                    <div className=' flex sm:flex-row flex-col justify-center my-5'>
                                        <button onClick={() => { setIsCreateDataFormateOpen(true) }} className=' w-full sm:w-auto  justify-self-end rounded hover:scale-105 transition-all duration-200 ease-in text-purple-600 py-1 px-2 shadow shadow-purple-300 sm:mb-0 mb-3 sm:mr-3'>Add Data Format</button>
                                        <button onClick={() => { setIsPrimaryKeySectionOpen(true) }} className=' w-full sm:w-auto justify-self-end rounded hover:scale-105 transition-all duration-200 ease-in text-green-600 py-1 px-2 shadow shadow-green-300 sm:mb-0 mb-3 sm:mr-3'>Select Primary Key</button>
                                        <button onClick={() => { setIsDataPointOpen(true); createCompanyToken() }} className=' w-full sm:w-auto justify-self-end rounded hover:scale-105 transition-all duration-200 ease-in text-blue-600 py-1 px-2 shadow shadow-blue-300'>Create Data Point</button>
                                    </div>
                                </div>

                                <div className=' bg-white rounded shadow shadow-gray-300 p-5 mt-3'>
                                    <div>
                                        <p className=' text-base sm:text-lg text-gray-700 text-center font-semibold'>Keys of the company</p>
                                        {
                                            currantCompany.keys.map((key, index) => {
                                                return (
                                                    <div key={index} className="">
                                                        <p className=' text-sm sm:text-base my-3 text-gray-700 '>{key.keyName}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            currantCompany &&
                            <DataFormatComponent currantCompany={currantCompany} />
                        }

                    </div>
                </div>
            </div>

            {
                isDataPointOpen &&
                <DataPoint setIsDataPointOpen={setIsDataPointOpen} companyToken={companyToken} />
            }
            {
                isCreateDataFormateOpen &&
                <DataFormate setIsCreateDataFormateOpen={setIsCreateDataFormateOpen} companyId={companyId} setCurrantCompany={setCurrantCompany} />

            }
            {
                isPrimaryKeySectionOpen && currantCompany &&
                <PrimaryKeySelect setIsPrimaryKeySectionOpen={setIsPrimaryKeySectionOpen} setCurrantCompany={setCurrantCompany} company={currantCompany} />

            }
        </div>
    )
}

export default SingleCompany