import React from 'react'
import Header from '../Components/Header';
import Companies from '../Components/Companies';
import Keys from '../Components/Keys';
import Sidebar from '../Components/Sidebar';

function Home() {

    return (
        <div className=' bg-gray-100 min-h-screen w-full'>
            <Header />
            <div className=' w-full pt-18 flex'>
                <Sidebar Home />
                <div className='pt-20 ml-3 sm:ml-5 w-[calc(100%-70px)] sm:w-[calc(100%-200px)] md:px-16 lg:px-20 xl:px-32 overflow-hidden'>
                    <div className=' w-full mt-3 pr-5 overflow-hidden'>
                        <div className=' mb-5'>
                            <Companies />
                        </div>
                        <Keys />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home