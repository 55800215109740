import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';

function DataPoint({ setIsDataPointOpen, companyToken }) {

    const [copyState, setCopyState] = useState(false);

    return (
        <div className='fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-50'>
            <div className=' w-full p-5 flex justify-center'>
                <div className='  w-full sm:w-[400px] h-[500px] bg-white shadow shadow-gray-300 rounded relative'>

                    {
                        companyToken &&
                        <div className=' w-full h-full px-3'>
                            <p className=' my-3 text-lg font-semibold text-center border-b border-gray-300 pb-5'>Company Token</p>
                            <p className=' break-words'>{companyToken}</p>
                            {
                                copyState &&
                                <p className='  mt-5 text-green-600 text-center'>Copied !!</p>
                            }
                            <CopyToClipboard text={companyToken}
                                onCopy={() => setCopyState(true)}>
                                <button className=' absolute bottom-3 right-1/2 translate-x-1/2 shadow shadow-blue-500 text-blue-700 hover:bg-blue-500 hover:text-white rounded p-1'>Copy</button>
                            </CopyToClipboard>
                        </div>
                    }
                </div>

            </div>
            <button onClick={() => { setIsDataPointOpen(false); setCopyState(false) }} className=' z-[100] py-1 px-2 rounded bg-red-500 text-white fixed top-3 right-3 hover:scale-105 transition-all ease-in '>Close</button>
        </div>
    )
}

export default DataPoint