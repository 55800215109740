import React, { useState } from 'react'
import { AiFillCaretDown, AiOutlineLogin, AiOutlineUser } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Logout } from "../Redux/User_slice"
import { useDispatch } from 'react-redux';

function Header() {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const LogoutFunc = () => {
        dispatch(Logout());
    }

    document.addEventListener('mouseup', function (e) {

        if (isDropdownOpen) {
            let container = document.getElementById('container');
            if (!container.contains(e.target)) {
                container.style.display = 'none';
            }
        }
    });

    return (
        <div className=' w-full h-[72px] bg-white shadow shadow-gray-300 fixed top-0 left-0 z-50'>
            <div className=' h-full w-full flex justify-between items-center px-5 sm:px-12'>
                <div className=' flex items-center'>
                    <Link to='/' className='font-semibold'>
                        <span className=' text-lg sm:text-xl cursor-pointer'>Logo</span>
                    </Link>
                </div>
                <div onClick={toggleDropdown} className=' flex items-center cursor-pointer'>
                    <p className='mx-1 text-base sm:text-lg'>User</p>
                    <AiFillCaretDown className=' text-lg' />
                </div>
                {
                    isDropdownOpen ?
                        <div id='container' className=' p-1 bg-white absolute top-16 right-2 shadow shadow-gray-200 rounded'>
                            <Link onClick={LogoutFunc} to="/login" className=' flex items-center px-5 py-1 hover:bg-black hover:bg-opacity-10 rounded'>
                                <AiOutlineLogin className=' text-red-500 mr-2' />
                                <p className=' text-red-400'>Logout</p>
                            </Link>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default Header