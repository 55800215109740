import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Host } from '../Data';
import ObjectDataFormat from './ObjectDataFormat';

function DataFormatComponent({ currantCompany }) {

    const location = useLocation();
    let companyId = location.pathname.split('/')[2];
    const user = useSelector(state => state.User.User);
    const [formateKeys, setFormateKeys] = useState([]);
    const [companyDataFormatArray, setCompanyDataFormatArray] = useState([]);


    useEffect(() => {

        const getCompany = async () => {

            const response = await axios.get(`${Host}/api/company/get_company/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            setFormateKeys(Object.keys(response.data.dataFormat));

            //  create an array from a object 
            const createArrayFromObject = (object) => {
                return Object.keys(object).map(key => object[key]);
            }

            setCompanyDataFormatArray(createArrayFromObject(response.data.dataFormat));
        }

        getCompany();

    }, [currantCompany]);


    return (
        <div className=' mt-3 sm:mt-5 bg-white shadow shadow-gray-300 rounded w-full py-5 px-10'>
            <p className=' text-lg sm:text-xl text-center font-semibold'>Data Format</p>
            <div>
                {
                    formateKeys.length > 0 && formateKeys.map((key, index) => {
                        return (
                            <div key={index} className=' my-3'>
                                <p className='font-semibold text-base sm:text-lg'>{key} : {"{"}</p>
                                {
                                    companyDataFormatArray.length > 0 && companyDataFormatArray.map((item, i) => {

                                        if (index === i && item.type === "String") {
                                            return (
                                                <div key={i} className=" my-2 ml-5">
                                                    <p className=' my-2 text-gray-600 sm:text-base text-sm'> <span className=' font-semibold text-gray-800'>Type</span> : String</p>
                                                    <p className='text-gray-600'> <span className=' font-semibold text-gray-800 sm:text-base text-sm'>Value</span> : {item.value}</p>
                                                </div>
                                            )
                                        }

                                        if (index === i && item.type === "Object") {
                                            return (
                                                <div key={i} className=" my-2 ml-5">
                                                    <p className=' my-2 text-gray-600'> <span className=' font-semibold text-gray-800 sm:text-base text-sm'>Type</span> : Object</p>
                                                    <div className='text-gray-600'> <span className=' font-semibold text-gray-800 sm:text-base text-sm'>Value</span> : {"{"} <ObjectDataFormat value={item.value} />
                                                        <p>{"}"}</p>

                                                    </div>
                                                </div>
                                            )
                                        }

                                        if (index === i && item.type === "Array") {

                                            return (
                                                <div key={i} className=" my-2 ml-5">
                                                    <p className=' my-2 text-gray-600 sm:text-base text-sm'> <span className=' font-semibold text-gray-800'>Type</span> : Array</p>
                                                    <p className='text-gray-600 sm:text-base text-sm'> <span className=' font-semibold text-gray-800'>Value</span> : {item.value}</p>
                                                </div>
                                            )
                                        }

                                        if (index === i && item.type === "ArrayObject") {

                                            return (
                                                <div key={i} className=" my-2 ml-5">
                                                    <p className=' my-2 text-gray-600 sm:text-base text-sm'> <span className=' font-semibold text-gray-800'>Type</span> : ArrayObject</p>
                                                    <div className='text-gray-600 sm:text-base text-sm'> <span className=' font-semibold text-gray-800'>Value</span> : {"{"} <ObjectDataFormat value={item.value} />
                                                        <p>{"}"}</p>

                                                    </div>
                                                </div>
                                            )
                                        }
                                    })

                                }
                                <p className='font-semibold text-lg'>{"}"}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default DataFormatComponent