import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Host } from '../Data';
import { AllKeysFatchSuccess, AddKeySuccess, DeleteKeySuccess, UpdateKeySuccess } from "../Redux/Keys_slice"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Keys() {

    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState(false);
    const [isAddModeOpen, setIsAddModeOpen] = useState(false);
    const [currantKey, setCurrantKey] = useState(null);
    const [curantCompanyId, setCurantCompanyId] = useState('');
    const [keyName, setKeyName] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const AllCompanies = useSelector(state => state.Companies.Companies);
    const AllKeys = useSelector(state => state.Keys.Keys);
    const User = useSelector(state => state.User.User);
    const dispatch = useDispatch();
    const [keyActibationStatus, setKeyActibationStatus] = useState(true);

    const toggleUpdateMode = (key, companyId, isActive) => {
        setIsUpdateModeOpen(!isUpdateModeOpen);
        setCurrantKey(key);
        setCurantCompanyId(companyId);
        setKeyName(key);

        if (isActive === true) {
            setKeyActibationStatus("true");
        } else {
            setKeyActibationStatus("false");
        }
    }

    const toggleAddMode = () => {
        setIsAddModeOpen(!isAddModeOpen);
        setSelectedCompanyId('');
        setKeyName('');
        if (isUpdateModeOpen) {
            setIsUpdateModeOpen(false)
        }
    }

    const CreateKeyFunc = async () => {

        if (selectedCompanyId === '') {
            toast.error('Please select a company');
            return;
        }

        try {

            const response = await axios.post(`${Host}/api/company/create_keys/${selectedCompanyId}`, { keys: keyName }, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })

            setKeyName('');
            dispatch(AddKeySuccess(response.data))
            toast.success('Keys created successfully');
            toggleAddMode()

        } catch (err) {

            if (err.response.data === 'key already exist') {

                toast.error("key already exist")

            }
            console.log(err.response.data);
        }
    };

    const updateKeyName = async (companyId, oldKey) => {

        if (!keyName) {
            toast.error("key name is required")
            return
        }

        try {

            const keyData = {
                key: keyName
            }

            if (keyActibationStatus === "true") {
                keyData.isActive = true
            } else {
                keyData.isActive = false
            }

            const res = await axios.post(`${Host}/api/company/update_key/${companyId}/${oldKey}`, keyData, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })

            dispatch(UpdateKeySuccess(res.data));
            console.log(res.data)
            toast.success('Key name updated successfully');
            toggleUpdateMode(res.data.keyName, res.data.companyId, res.data)

        } catch (error) {

            toast.error('Key name not updated')
            console.log(error);
        }
    }


    const deleteKey = async (key) => {

        try {

            await axios.delete(`${Host}/api/company/delete_key/${key.companyId}/${key.key}`, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })

            dispatch(DeleteKeySuccess({ key: key.key, companyName: key.companyName }))
            toast.success('Key deleted successfully');

        } catch (err) {
            toast.error('Key not deleted');
        }
    }

    useEffect(() => {

        const fetchKeys = async () => {

            try {

                const response = await axios.get(`${Host}/api/company/get_keys`, {
                    headers: {
                        'Authorization': `Bearer ${User.token}`
                    }
                })

                dispatch(AllKeysFatchSuccess(response.data));

            } catch (err) {

                console.log(err);
            }
        }
        fetchKeys();

    }, [User, AllCompanies])


    return (

        <div className=' w-full overflow-x-scroll scrollbar'>
            <div className='w-[600px] sm:w-full bg-white shadow p-5 sm:p-10 rounded'>

                <div className=' flex flex-col sm:flex-row justify-between'>
                    <p className=' pb-4 font-semibold'>Keys Name</p>
                    {
                        !isAddModeOpen ?
                            <button onClick={toggleAddMode} className=' border-[1] rounded p-1 text-green-500 shadow shadow-green-300 ml-2 hover:scale-105 hover:text-green-600 transition-all duration-100 ease-in'>Add New</button>
                            :
                            <div className=' flex flex-col'>
                                <div className=' flex justify-between'>
                                    <input type="text" placeholder='Enter Keys Name' value={keyName} onChange={(e) => { setKeyName(e.target.value) }} className=' w-full focus:outline-none border border-blue-500 rounded py-1 px-2 mr-2' />
                                    <div className=' flex'>
                                        <button onClick={CreateKeyFunc} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Create</button>
                                        <button onClick={toggleAddMode} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Close</button>
                                    </div>
                                </div>

                                <select value={selectedCompanyId} onChange={(e) => { setSelectedCompanyId(e.target.value) }} name="" id="" className=' justify-self-end mt-3 focus:outline-none border-[1px] border-blue-500 py-1 px-2 rounded'>
                                    <option value="">Select Company</option>
                                    {
                                        AllCompanies.map((company, index) => {
                                            return (
                                                <option key={index} value={company._id}>{company.companyName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                    }
                </div>


                <div className='w-full mt-3 grid grid-cols-4 justify-items-center p-3 rounded border-b-[1px] border-gray-100 font-semibold text-gray-700 bg-gray-100'>
                    <span className=' justify-self-start'>Key</span>
                    <span>Company</span>
                    <span>Status</span>
                    <span className=' justify-self-end'>Options</span>
                </div>

                {
                    AllKeys.length > 0 && AllKeys.map((key, index) => {
                        return (
                            <div key={index} className='w-full grid grid-cols-4 py-3 border-b-[1px]'>

                                {
                                    isUpdateModeOpen && currantKey && curantCompanyId && currantKey === key.key && curantCompanyId === key.companyId ?
                                        <input className='w-full focus:outline-none border-[1px] border-blue-400 rounded mr-5 py-[]6px px-2' placeholder='Enter Company Name' value={keyName} onChange={(e) => setKeyName(e.target.value)} />
                                        :
                                        <div className=' text-gray-700 font-semibold text-sm flex items-center'>
                                            <span>{key.key}</span>
                                        </div>
                                }
                                <div className='flex items-center justify-center'>
                                    <span className={` text-gray-700 text-sm p-1 rounded`}>{key.companyName}</span>
                                </div>

                                {
                                    isUpdateModeOpen && currantKey && curantCompanyId && currantKey === key.key && curantCompanyId === key.companyId ?
                                        <select name="" id="" value={keyActibationStatus} onChange={(e) => setKeyActibationStatus(e.target.value)} className=' border border-blue-500 rounded py-1 focus:outline-none'>
                                            <option value="">Select Status</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>Diactive</option>
                                        </select>
                                        :
                                        <div className='flex items-center justify-center'>
                                            <span className={`${key.isActive ? " bg-blue-50 text-sm font-semibold text-blue-700 rounded p-1" : "bg-red-50 text-red-700 text-sm font-semibold rounded p-1"}`}>{key.isActive ? "Active" : "Diactivated"}</span>
                                        </div>

                                }

                                {
                                    isUpdateModeOpen && currantKey && curantCompanyId && currantKey === key.key && curantCompanyId === key.companyId ?
                                        <div className=' flex items-center justify-end'>
                                            <button onClick={() => updateKeyName(key.companyId, key.key)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Save</button>
                                            <button onClick={() => setIsUpdateModeOpen(false)} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Closes</button>
                                        </div>

                                        :

                                        <div className=' flex items-center justify-end'>
                                            <button onClick={() => toggleUpdateMode(key.key, key.companyId, key.isActive)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Edit</button>
                                            <button onClick={() => { deleteKey(key) }} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Delete</button>
                                        </div>
                                }
                            </div>
                        )
                    })
                }

            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default Keys