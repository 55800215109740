import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './User_slice';
import CompanyReducer from './Companies_slice';
import KeysReducer from './Keys_slice';

export const store = configureStore({
    reducer: {
        User: UserReducer,
        Companies: CompanyReducer,
        Keys: KeysReducer,
    },
})