import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { Host } from "../Data"
import { useSelector } from 'react-redux'

function PrimaryKeySelect({ setIsPrimaryKeySectionOpen, company, setCurrantCompany }) {

    const [primaryKey, setPrimaryKey] = useState('');
    const user = useSelector(state => state.User.User);

    const SaveForainKey = async () => {

        if (primaryKey === '') {
            toast.error("Please enter a primary key name");
            return;
        }

        try {

            const res = await axios.post(`${Host}/api/company/set_primary_key/${company._id}`, { primaryKey }, {

                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            toast.success("Primary key saved");
            console.log(res.data);
            setCurrantCompany(res.data);
            setPrimaryKey('');

        } catch (error) {

            console.log(error);
            toast.error("Something went wrong");
        }
    }

    console.log(company);

    return (
        <div className=' fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-50'>
            <div className=' w-full p-5 flex justify-center'>
                <div className='  w-full sm:w-[400px] h-[500px] bg-white shadow shadow-gray-300 rounded relative'>
                    <p className=' text-center text-lg font-semibold my-3'>Select Primary Key</p>
                    <div className=' w-full p-5 flex justify-between'>
                        <div>
                            <label htmlFor="" className=' font-semibold'>Keys :</label>

                            <select value={primaryKey} onChange={(e) => { setPrimaryKey(e.target.value) }} name="" id="" className=' rounded border border-blue-500 py-1 px-2 focus:outline-none ml-3' >
                                <option value="">Select Key</option>
                                {
                                    company.keys.map((key, index) => {
                                        return (
                                            <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <button onClick={SaveForainKey} className=' p-1 rounded shadow shadow-blue-300 text-blue-700 hover:scale-105 transition-all ease-in'>Save</button>
                    </div>

                    <div className='w-full p-5'>
                        <p className=' font-semibold'>Primary Key : <span className=' text-gray-700 font-normal'>{company.primaryKey}</span></p>
                    </div>
                </div>
            </div>
            <button onClick={() => { setIsPrimaryKeySectionOpen(false) }} className=' z-[100] py-1 px-2 rounded bg-red-500 text-white fixed top-3 right-3 hover:scale-105 transition-all ease-in '>Close</button>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default PrimaryKeySelect